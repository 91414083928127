<template>
    <div>
        <nav-bar title="结算记录" url="/sale"></nav-bar>
        <div class="top">
            <div class="title">
                <div class="title-text">
                    结算记录
                </div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
            </div>
            <van-list v-model="loading" :finished="finish" finished-text="没有更多了" @load="get_list()">
                <van-cell-group class="group-user-list">
                    <van-cell v-for="(item,index) in list" :key="index" :title="'提现金额：￥'+item.with_money"
                        @click="url('/sale_withdraw_detail?id=' + item.id)">
                        <template slot="default">
                            <van-tag plain type="primary" v-if="item.with_state == 0">待审核</van-tag>
                            <van-tag plain type="success" v-if="item.with_state == 1">审核通过</van-tag>
                            <van-tag plain type="danger" v-if="item.with_state == 2">审核不过</van-tag>
                            <van-tag plain type="success" v-if="item.with_state == 3">提现成功</van-tag>
                            <van-tag plain type="danger" v-if="item.with_state == 4">提现失败</van-tag>
                            <van-tag plain type="warning" v-if="item.with_state == 5">提现中</van-tag>
                        </template>
                        <template slot="label">
                            <div>提现时间：{{item.with_submit_time}}</div>
                        </template>
                    </van-cell>
                </van-cell-group>
            </van-list>

            <div v-if="list.length == 0">
                <van-empty description="列表为空" />
            </div>
        </div>
    </div>
</template>

<script>
    import {
        sale_withdraw_list,
    } from '@/api/sale.js';
    export default {
        name: 'sale_withdraw',
        data() {
            return {
                list: [],
                finish: false,
                loading: false,
            }
        },
        methods: {

            //获取办理列表
            get_list() {
                var data = {
                    start: this.list.length,
                    count: 20,
                };
                this.loading = true;
                sale_withdraw_list(data).then(res => {
                    this.loading = false;
                    if (res.code == 1) {
                        this.list = this.list.concat(res.data.info);
                        this.finish = res.data.finish;
                    }
                });
            },

            url(path) {
                if (path.indexOf("http") != -1) {
                    window.location.href = path;
                } else {
                    if (path == this.$store.getters.unionid) {
                        this.$router.back();
                    } else {
                        this.$router.push(path);
                    }
                }
            },
        }
    }
</script>

<style scoped>
    .top {
        width: calc(100% - 40px);
        margin: 20px auto;
        background: #fff;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        margin-bottom: 20px;
    }

    .title {
        background: var(--main-color);
        height: 40px;
        line-height: 40px;
        color: #fff;
        padding-left: 20px;
        position: relative;
        font-family: 'FZCS';

    }

    .title-bg {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 999;
    }

    .title-bg img {
        height: 40px;
    }
</style>